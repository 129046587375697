<template>
  <section id="form" class="FormBox">
    <div class="Wrapper">
      <h2 class="Title Title--h2 FormBox-Title"></h2>
      <Form />
    </div>
  </section>
</template>

<script>
import Form from './Form'
import { textAnimation, isInViewport } from "../assets/utils/utils";

export default {
  name: "FormBox",
  components: {
    Form
  },
  data() {
    return {
      isTextTyped: false
    }
  },
  methods: {
    scrollHandler() {
      if (!this.isTextTyped && isInViewport('.FormBox')) {
        this.isTextTyped = true
        const texts = [
          {
            selector: '.FormBox-Title',
            text: this.$t('form.title')
          }
        ]

        textAnimation(texts)  
      }        
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollHandler)
    this.scrollHandler()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>

<style lang="scss">
.FormBox {
  padding: 60px 0 50px;
  scroll-margin-top: 70px;

  &-Title {
    height: 111px;

    @media (min-width: $screen-l) {
      height: 150px;
      margin-bottom: 88px;
    }
  }
}
</style>
